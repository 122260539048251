import { Component } from '@angular/core';
import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { KioskapiService } from '../services/kioskapi.service';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateConfigService } from '../services/translate-config.service';
import { UtilityService } from '../services/utility.service';
import { ApiService } from '../services/api.service';
import { cart_time, images_display_timer, KIOSK_AUTO_LOGIN, menu_items_timer, modalTimeout, page_redirection_time, setIntervalTimeOut, telemetryTimeout, terminalCheck, } from '../common/api';
import { TranslateService } from '@ngx-translate/core';
import { CartItemDetailPage } from '../cart-item-detail/cart-item-detail.page';
import { PageLoaderService } from '../services/page-loader.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {
  params: any;
  telemetry_blocker = false;
  forced_transmission = "";
  payment_progress_txt = "";
  payment_progress = "";
  payment_terminal_status = "";
  payment_terminal_status_txt = "";
  firmware_updating = false;
  software_version = "";
  ordering_avail = true;
  time_check = "OK";
  payment_initiated = false;
  check_nega = false;
  wait_card = false;
  telemetry_poller: any;
  maintain_timer: any;
  is_maintaince_mode_on = true;
  appSettings: any;
  shop: any;
  type = "0"
  menuItems = {};
  totalAmount: number;
  settings = {};
  orderType = "";
  currency = "¥";
  showDecimal = true;
  cartItems: [];
  cartItemCount: number;
  totalCartAmount: number = 0;
  payment: any;
  timer_start = true;
  menu_item: boolean;
  is_restaurant_closed_status = "NO";
  restaurant_status_interval: any;
  page_redirection_time_left = page_redirection_time;
  terminalResponse: any;
  hide_cancel_button: any;
  payprog_lock: any;
  subtype: any;
  deducted: any;
  attempted: any;
  time_left_counter: any;
  time_left = 0;
  time_stop = false;
  show_cancel_button = false;
  mid_cancel = false;
  store_close_status;
  restaurant_mode;
  app_maintanance_description2;
  app_ella_maintanance_description2;
  app_store_closed_description2;
  obj_is_restaurant_closed;
  obj_checkout;
  obj_restaurantDetails;
  obj_appsetting;
  obj_redirect_event;
  obj_menuItem;
  terminalId;
  cartItemDetail: any = [];
  haveMaxEllaCoffee: boolean
  ella_max_drinks;
  coffeeCount;
  time_left_redirection_counter: any;
  ella_limit;
  terminal_modal;
  store_info
  is_ella_maintaince_mode_on:boolean
  home_banner_image:any;
  display_images:any;
  menuItems_timer:any;
  menuItems_length;
  res_settings:any;
  one_pager_kiosk: any;
  cat_show: string;
  menuItems_withoutCat:any = []

  constructor(
    private router: Router,
    private kioskauthservice: KioskauthService,
    private route: ActivatedRoute,
    private modalController: ModalController,
    private store: LocalStoreService,
    private translateConfigService: TranslateConfigService,
    private utilityService: UtilityService,
    private api: ApiService,
    public alertController: AlertController,
    private translate: TranslateService,
    private kiosk_apiService: KioskapiService,
    private pageLoaderService: PageLoaderService
  ) {
    this.translateConfigService.getDefaultLanguage();
    this.showDecimal = true;
    if (this.store.getCountry()) {
      let countryData = this.store.getCountry();
      this.currency = countryData['master_country_currency_code'];
      if (countryData['master_country_currency_decimal_places'] == 0) {
        this.showDecimal = false;
      }
    }
    this.check_auth();
    this.getAppSettings();
  }


  ngOnInit() {
    this.menu_item = false;
    this.is_restaurant_closed();
    this.getRestaurantDetails();
    this.check_auth();
    this.time_stop = false;
    this.time_left = cart_time;
    clearInterval(this.time_left_counter);
    clearInterval(this.maintain_timer);
    this.kiosk_apiService.logEvents("HOME");
    this.kiosk_apiService.audit_log("HOME");
  }

  is_restaurant_closed() {
    this.obj_is_restaurant_closed = this.kiosk_apiService.restaurant_Operational()
      .subscribe(
        (res) => {
          // this.pageLoaderService.pageLoaderPersistent(false)
          if (res.statusCode == 200) {
            if (res.aaData == true) {
              this.is_restaurant_closed_status = "NO";
            } else {
              this.is_restaurant_closed_status = "YES";
            }
            // localStorage.removeItem('restaurant');
            // this.store.setRestaurant(res.aaData);
          }
        },
      );
  }


  ionViewWillEnter() {
    this.terminal_modal = this.store.get('terminal_modal')
    setInterval( () =>{
      this.terminal_modal = this.store.get('terminal_modal')
    },500)
    this.kiosk_apiService.telemetry();
    this.telemetry_poller = setInterval(
      ()=>{
       this.kiosk_apiService.telemetry()
      }
    , terminalCheck);
    this.payment_printing_setting();
    this.ella_drink_limit()
    this.getMenuItems();
    this.reset_time();
    this.store_close_status = setInterval(
      () => {
        this.is_restaurant_closed();
      }
      , 10000);

    this.restaurant_mode = setInterval(
      () => {
        this.getRestaurantDetails();
      }
      , 12000);
      // this.display_images =setInterval(()=>{
      //   this.getAppSettings();
      // },images_display_timer)
      this.menuItems_timer =setInterval(()=>{
        this.getMenuItems();
      },menu_items_timer)

    this.clock_Timer();
    this.orderType = this.store.get('order_type');
    this.check_auth();
    this.getCartItems();
    if (this, this.cartItemCount == 0) {
      clearInterval(this.time_left_counter);
    }
    this.terminalId = this.store.get('terminalid')

  }

  getMenuItems() {
    //Get Menu Details
    this.obj_menuItem = this.kiosk_apiService
      .Menu_items()
      .subscribe((response) => {
        if (response.status === false) {
          this.kioskauthservice.onError(response.message);
        } else {
          if(this.one_pager_kiosk == false || this.one_pager_kiosk == "false" || this.one_pager_kiosk == null || this.one_pager_kiosk == undefined){
            if (response.final_response) {
              this.cat_show = "true";
              this.menu_item = true;
              this.menuItems = response.final_response;
              this.menuItems_length = Object.keys(this.menuItems).length
              
            } else {
              this.menu_item = true;
              this.cat_show = "true";
              this.menuItems = response.aaData;
              this.menuItems_length = Object.keys(this.menuItems).length
            }
          }else{
            if (response.final_response) {
              this.menu_item = true;
              this.cat_show = "false";
              this.menuItems_withoutCat = response.final_response;
              this.menuItems_length = Object.keys(this.menuItems).length
              
            } else {
              this.cat_show = "false";
              this.menu_item = true;
              this.menuItems_withoutCat = response.aaData;
              this.menuItems_length = Object.keys(this.menuItems).length
            }
          }
        }
      },
        error => {
          // this.pageLoaderService.pageLoaderPersistent(false) //----------loader
          this.technical_error()
        }
      );
  }

  check_auth() {
    if (!this.kioskauthservice.is_logged_in()) {
      this.kioskauthservice.logout();
    }
  }

  async redirectOnDetailPage(item) {

    this.obj_redirect_event = this.kiosk_apiService.restaurant_Operational()
      .subscribe(
        (res) => {
          if (res.statusCode == 200) {
            if (res.aaData == true) {
              this.redirectOnItemDetailPage(item);
            } else {
              this.kioskauthservice.isRestaurantOpenAlert();
              return false;
            }
          }
        },
        error => {

          this.technical_error();
        }
      );
  }

  async redirectOnItemDetailPage(item) {
    if (item.is_food_category_time_available == false) {
      this.kioskauthservice.itemNotAvailableAlert();
      return false;
    } else {
      if (item.master_menu_item_is_combo == "YES") {
        if (item.master_food_category_uuid == '12844a2db14f510f90333983f1fa07ad' || item.master_food_category_uuid == '1be5953589919b2b686f94e2b68dccea' || item.master_food_category_uuid == 'a16b3341e0203ea1e3435c5c04b177bd') {
          this.router.navigateByUrl(`product-combo-detail?id=` + item.master_menu_item_uuid);
        }
        else {
          this.router.navigateByUrl(`product-detail?id=` + item.master_menu_item_uuid);
        }
        // this.router.navigateByUrl(`product-detail?id=` + item.master_menu_item_uuid);
      }
      else {
        this.router.navigateByUrl(`product-detail?id=` + item.master_menu_item_uuid);
      }
    }
  }

  getCartItems() {
    let cartDetail = this.store.getUserCart();
    this.cartItems = cartDetail;
    this.cartItemCount = 0;
    this.totalCartAmount = 0;
    Object.entries(cartDetail).forEach(([k, v]) => {
      this.cartItemCount = +v[0].total_quantity + +this.cartItemCount;
      this.totalCartAmount = +v[0].total + +this.totalCartAmount;
      if (this.timer_start == true || this.cartItemCount == 0) {
        this.time_left = cart_time;
        clearInterval(this.time_left_counter);
      }

      if (this.cartItemCount != 0) {
        this.time_left_counter = setInterval(() => {
          this.time_left -= 1;
          if (this.time_left == 0) {
            clearInterval(this.time_left_counter);
            this.store.setUserCart([]);
            this.store.remove("formFields");
            this.store.remove("cart");
            this.alertController.dismiss();
            let Autologin = KIOSK_AUTO_LOGIN
            this.store.hardlogout()
            location.href = Autologin + '/' + this.terminalId;

            return false;
          }
        }, setIntervalTimeOut);
      }
    })
  }

  getTotalQuantity(item) {
    return item[0].total_quantity;
  }


  checkOut() {
    this.kiosk_apiService.logEvents("CHECKOUT");
    this.kiosk_apiService.audit_log("CHECKOUT");
    this.obj_checkout = this.kiosk_apiService.restaurant_Operational()
      .subscribe(
        (res) => {
          if (res.statusCode == 200) {
            if (res.aaData == true) {
              clearInterval(this.time_left_counter);
              this.router.navigate([`payment-method`]);
            } else {

            }
          }
        },
        error => {
          this.technical_error()
        }
      );
  }

  removeItemFromCart(index) {
    let cart = this.store.getUserCart();
    cart.splice(index, 1);
    this.store.setUserCart(cart);
    this.ionViewWillEnter();
    this.kiosk_apiService.logEvents("CART_ITEM_REMOVED");
    this.kiosk_apiService.audit_log("CART_ITEM_REMOVED");
  }

  editItem(index, itemUuid) {
    this.router.navigateByUrl('/product-detail?id=' + itemUuid + '&ci=' + index);
  }

  /**
  * Function to add pull down feature 
  * @param event 
  */
  doRefresh(event) {
    // this.pageLoaderService.pageLoaderPersistent(true) //----------loader
    this.kiosk_apiService.restaurant_Details()
      .subscribe((res) => {
        // this.pageLoaderService.pageLoaderPersistent(false)
        if (res.statusCode == 200) {
          localStorage.removeItem('restaurant');
          this.store.set('restaurant', res.aaData);
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          event.target.complete();
          this.maintain_timer = setInterval(
            () => {
              if (this.shop.master_restaurant_status == 'M_ON') {
                this.is_maintaince_mode_on = true;
              }
              else {
                this.is_maintaince_mode_on = false;
              }
              this.is_maintaince_mode_on = false;
            }
            , telemetryTimeout);
        } else {
          event.target.complete();
        }
      }, err => {
        event.target.complete();
        // this.pageLoaderService.pageLoaderPersistent(false) //----------loader
      });
  }

  getRestaurantDetails() {
    // this.pageLoaderService.pageLoaderPersistent(true) //----------loader
    this.obj_restaurantDetails = this.kiosk_apiService.restaurant_Details()
      .subscribe((res) => {
        // this.pageLoaderService.pageLoaderPersistent(false) //----------loader
        if (res.statusCode == 200) {
          localStorage.removeItem('restaurant');
          this.store.set('restaurant', res.aaData);
          this.store_info = res.aaData
          if(this.store_info.master_restaurant_status == "ELLA_M_ON"){
            this.is_ella_maintaince_mode_on = true;
          }
          else{
            this.is_ella_maintaince_mode_on = false;
          }
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          // this.getAppSettings();
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null'
          if (this.shop.master_restaurant_status == 'M_ON') {
            this.is_maintaince_mode_on = true;
          }
          else {
            this.is_maintaince_mode_on = false;
          }
        }
      }, error => {
        // this.pageLoaderService.pageLoaderPersistent(false) //----------loader.
        this.technical_error();
      });
  }
  /**
  * Function to get app seting
  */
  getAppSettings() {

    this.obj_appsetting = this.kiosk_apiService.appsettings().subscribe(response => {
      if (response.status) {
        this.appSettings = response.aaData;
        this.settings = this.appSettings;

        this.app_maintanance_description2 = response.aaData.app_settings.app_maintanance_description ? response.aaData.app_settings.app_maintanance_description : environment.M_ON;
        this.app_ella_maintanance_description2 = response.aaData.app_settings.app_ella_maintanance_description ? response.aaData.app_settings.app_ella_maintanance_description : environment.ELLA_M_ON;
        this.app_store_closed_description2 = response.aaData.app_settings.app_store_closed_description ? response.aaData.app_settings.app_store_closed_description : environment.STORE_CLOSED;
        this.home_banner_image = response.aaData.app_settings.app_store_banner_description_kiosk 
      }
    }, err => {

    });
  }


  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  show_payment_simulation() {
    this.router.navigateByUrl('/payment-method2');
  }


  ionViewDidLeave() {
    clearInterval(this.store_close_status);
    clearInterval(this.restaurant_mode);
    clearInterval(this.telemetry_poller)
    clearInterval(this.terminal_modal)
    clearInterval(this.time_left_counter);
    clearInterval(this.maintain_timer);
    clearInterval(this.time_left_redirection_counter);
    this.obj_is_restaurant_closed.unsubscribe();
    this.obj_restaurantDetails.unsubscribe();
    this.obj_menuItem.unsubscribe();
    this.obj_appsetting.unsubscribe();
    clearInterval( this.display_images)
    clearInterval(this.menuItems_timer)

  }

  switchOrderType(type) {
    this.orderType = type;
    this.store.set("order_type", type);
  }


  async openSheetModal(item) {
    const modal = await this.modalController.create({
      component: CartItemDetailPage,
      cssClass: 'app-cart-item-detail1',
      mode: 'md',
      componentProps: {
        data: item
      },
    });
    setTimeout(() => { modal.dismiss(); }, modalTimeout);
    return await modal.present();
  }

  clock_Timer() {
    setInterval(function () {
      var dt = new Date();
      var ampm = dt.getHours() >= 12 ? 'PM' : 'AM';
      var clock_time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds() + " " + ampm;

      if (clock_time === '7:0:0 AM' || clock_time === '7:30:0 PM' || clock_time === '19:30:0 PM') {
        window.location.reload();
      }
    });
  }

  async error_modal() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: "Payment Failed",
      message: "Please Tab Again",
      buttons: [
        {
          text: 'Done',
          cssClass: '',
          role: 'cancel',
        }
      ]
    });
    await alert.present();
  }

  go_to_maintenance() {
    location.href = "http://192.168.10.11:9001";
  }


  reset_time() {
    this.time_left = cart_time;
    clearInterval(this.time_left_redirection_counter);
    this.page_redirection_time_left = page_redirection_time;

    if (this.page_redirection_time_left = page_redirection_time) {

      this.time_left_redirection_counter = setInterval(() => {
        this.page_redirection_time_left -= 1;
        if (this.page_redirection_time_left == 0) {
          clearInterval(this.time_left_redirection_counter);
          this.kiosk_apiService.terminal_dismiss()
          clearInterval(this.telemetry_poller)
          let Autologin = KIOSK_AUTO_LOGIN
          this.store.hardlogout()
          location.href = Autologin + '/' + this.terminalId;
          return false;
        }
      }, setIntervalTimeOut);
    }
  }

  logScrollStart(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
    this.time_left = cart_time;
  }

  logScrolling(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
    this.time_left = cart_time;
  }

  logScrollEnd(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
    this.time_left = cart_time;
  }

  is_item_ella(item) {
    if (item.master_menu_type == 'ELLA') {
      return true;
    }
    else {
      return false;
    }
  }


  is_category_coffee(obj) {
    var obj3 = obj.value;
    var obj4 = obj3 ? obj3[0] : {};
    var index = "NO"
    if (obj4.master_food_category_type == "ELLA" || obj4.master_food_category_type == "ella") {
      index = "YES";
    }
    return index;

  }

  async technical_error() {
    const alert = this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: 'Oops!',//'Please Tap Again',
      message: '<h2>Looks like we hit a snag! You will be redirected back in 5 seconds</h2>',
      buttons: [
        {
          text: '',
          cssClass: 'cross',
          handler: () => {
            this.alertController.dismiss();
            let Autologin = KIOSK_AUTO_LOGIN
            this.store.hardlogout()
            location.href = Autologin + '/' + this.terminalId;
          }
        }
      ]
    });
    setTimeout(() => {
      let Autologin = KIOSK_AUTO_LOGIN
      this.store.hardlogout()
      location.href = Autologin + '/' + this.terminalId;
      this.alertController.dismiss();
    }, 5000);
    (await alert).present();
  }

  isItemCartAvaliable() {
    this.kiosk_apiService.isItemCartAvaliable().subscribe(
      (res) => {
        if (res.statusCode == 200) {
          this.checkOut()
        }
        else {
          clearInterval(this.time_left_redirection_counter)
          clearInterval(this.time_left_counter);
          this.soldItemModal(res)
        }
      }
    )
  }

  ella_drink_limit() {
    this.kiosk_apiService.restaurant_Operational()
      .subscribe(
        (res) => {
          this.ella_limit = res.ella_max_drinks
        },
      );
  }


  async soldItemModal(data) {
    let itemImage = data.message.item_image
    if (itemImage == undefined || itemImage == '') {
      itemImage = "assets/image/bell.svg"
    }
    else {
      itemImage = data.message.item_image
    }
    let message = data.message.error
    let html = `<div class="custom-head">
    <span><img src="`+ itemImage + `"></span>
    <h2>Sorry</h2>
    <p>`+ message + `</p>
    </div>`
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class default-modal no-default-image',
      mode: 'md',
      message: html,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.reset_time();
            if (this.cartItemCount != 0) {
              this.time_left_counter = setInterval(() => {
                this.time_left -= 1;
                if (this.time_left == 0) {
                  clearInterval(this.time_left_redirection_counter);
                  this.store.setUserCart([]);
                  this.store.remove("formFields");
                  this.store.remove("cart");
                  let Autologin = KIOSK_AUTO_LOGIN
                  this.store.hardlogout()
                  location.href = Autologin + '/' + this.terminalId;
                  return false;
                }
              }, setIntervalTimeOut);
            }
          }
        }
      ]
    });
    setTimeout(() => {
      this.alertController.dismiss();
      if (this.cartItemCount != 0) {
        this.time_left_counter = setInterval(() => {
          this.time_left -= 1;
          if (this.time_left == 0) {
            clearInterval(this.time_left_redirection_counter);
            this.store.setUserCart([]);
            this.store.remove("formFields");
            this.store.remove("cart");
            let Autologin = KIOSK_AUTO_LOGIN
            this.store.hardlogout()
            location.href = Autologin + '/' + this.terminalId;
            return false;
          }
        }, setIntervalTimeOut);
      }
      this.reset_time();
    }, 5000);
    await alert.present();
  }

  payment_printing_setting() {
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
    let data = {
      "master_restaurant_uuid": master_restaurant_uuid
    }
    this.kiosk_apiService.payment_printing_setting(data)
      .subscribe(
        (res) => {
          this.res_settings = res.aaData.module_permissions
          this.one_pager_kiosk = this.res_settings.one_pager_kiosk
        },
        error => {
        }
      );
  }
} 